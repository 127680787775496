// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booth-ru-js": () => import("./../../../src/pages/booth.ru.js" /* webpackChunkName: "component---src-pages-booth-ru-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-materials-ru-js": () => import("./../../../src/pages/materials.ru.js" /* webpackChunkName: "component---src-pages-materials-ru-js" */),
  "component---src-pages-products-ru-js": () => import("./../../../src/pages/products.ru.js" /* webpackChunkName: "component---src-pages-products-ru-js" */),
  "component---src-pages-smm-guide-ru-js": () => import("./../../../src/pages/smm-guide.ru.js" /* webpackChunkName: "component---src-pages-smm-guide-ru-js" */)
}

